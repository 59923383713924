import {
  ERoles,
  FR_STATUS,
  MONTH_FR,
  STATUS_COLOR,
  checkDefaultManagerSelected,
  craActivation,
  craSendReminder,
  exportFileXlxs,
  formatDate,
  getEstablishmentName,
  getFullName,
  queryClient,
  useCraFindMany,
  useMe,
} from '@commons';
import {
  EAdditionalServiceStatus,
  EAdditionalServiceType,
  ECraVisibilityActions,
  ECraStatus,
  EExportModel,
  EMissionCraValidatedBy,
  ETaskStatus,
} from '@freelancelabs/teoreme-commons';
import {
  IJoinedCra,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons/dist/lib/api/responses/cra';
import { useMutation } from '@tanstack/react-query';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showValidateCraModal } from 'components/modals/DisplayValidateCraModal';
import { showRejectCraModal } from 'components/modals/RejectCraModal';
import { showPatchCraValidatedModal } from 'components/modals/cra/PatchCraValidated';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { showGenerateInvoiceModal } from 'components/modals/invoices/GenerateInvoiceModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Box,
  Button,
  CheckBox,
  Container,
  CustomToolTip,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { SideBar } from 'components/ui/BootStrap/SideBar';
import {
  AddIcon,
  CheckIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  FastCashIcon,
  InfoIcon,
  RefreshIcon,
  SvgEyeCloseIcon,
  SendIcon,
} from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import {
  canDisplayCorrectRejectButton,
  canDisplayGenerateSendCustomerInvoiceButton,
  canDisplayProofButton,
  canDisplayResendButton,
  canDisplayVisibilityButton,
  canDisplayValidateButton,
  checkDisplayCraMenuList,
  checkDisplayCreateGescomBDLButton,
  taskTypeMapping,
} from 'helpers';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useGlobalStore } from 'store';
import { useListCraStore } from 'store/cra';
import { Theme } from 'styles';
import { CraFiltersList } from './CraFiltersList';

type Item = Itemize<IJoinedCra>;

const Tabs = (
  <>
    <Tab variant="secondary" href={'/orders/cra/all'}>
      Tous
    </Tab>
    <Tab variant="secondary" href={'/orders/cra/awaiting'}>
      En attente
    </Tab>
    <Tab variant="secondary" href={'/orders/cra/to-be-validated'}>
      A valider
    </Tab>
    <Tab variant="secondary" href={'/orders/cra/by-customer'}>
      En cours de validation client
    </Tab>
    <Tab variant="secondary" href={'/orders/cra/validated'}>
      Validés
    </Tab>
    <Tab variant="secondary" href={'/orders/cra/archived'}>
      Archivés
    </Tab>
  </>
);

const columns = (
  status:
    | 'all'
    | 'awaiting'
    | 'to-be-validated'
    | 'validated'
    | 'archived'
    | 'by-customer',
  ReSendButton: any,
  ActivationButton: any,
  showMessage: any,
  me: any
) =>
  [
    {
      key: 'refCra',
      text: 'N°',
      render: (item: IJoinedCraWithInvoice) => {
        const paymentDeadlineFC = item?.mission?.additionalServices?.find(
          sc =>
            sc?.type === EAdditionalServiceType.FAST_CASH &&
            sc?.status === EAdditionalServiceStatus.ACTIVE
        );
        const deaddline = paymentDeadlineFC?.data?.deadline;
        return (
          <ALink
            href={`/delivery/missions/${item?.mission.reference}`}
            target="_blank"
          >
            {status === 'to-be-validated' &&
            //@ts-ignore
            item.hasFastCash &&
            paymentDeadlineFC ? (
              <Box>
                <a data-for={item.refCra} data-tip="" data-iscapture="true">
                  <ReactTooltip
                    id={item.refCra}
                    place={'bottom'}
                    variant={'dark'}
                    float={false}
                    style={{
                      backgroundColor:
                        deaddline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default,
                    }}
                  >
                    <Text color="white" fontSize={11}>
                      Fast cash {paymentDeadlineFC?.data?.deadline} jours
                    </Text>
                  </ReactTooltip>
                  <Box mr={20}>
                    <FastCashIcon
                      deaddline={deaddline}
                      fill={
                        deaddline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default
                      }
                    />
                  </Box>
                  {/* <Status
                    variantColor={deaddline > 10 ? 'warning' : 'error'}
                  ></Status> */}
                </a>
              </Box>
            ) : (
              <Box ml={status === 'to-be-validated' ? '40px' : 0}> </Box>
            )}
            <CustomToolTip
              text={item?.mission?.description}
              id={item?.refCra + '_description'}
            >
              <Text>{item.refCra}</Text>
            </CustomToolTip>
          </ALink>
        );
      },
    },
    {
      key: 'month',
      text: 'MOIS',
      sortKey: status !== 'all' && 'month',
      render: (item: IJoinedCra) => (
        <Box>
          <Text>
            {`${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
              item?.month as Date
            )?.getUTCFullYear()}`}
          </Text>
        </Box>
      ),
    },
    {
      key: 'submittedAt',
      text: 'RECU LE',
      sortKey: status !== 'all' && 'submittedAt',
      hidden: status !== 'to-be-validated',
      render: (item: IJoinedCra) => {
        const submitAt = item?.submittedAt
          ? item.submittedAt
          : item.stateChangedAt;
        const sumbitDate = submitAt ? new Date(submitAt) : undefined;
        return (
          <Box>
            <Text>{sumbitDate ? `${formatDate(sumbitDate)}` : 'N/A'}</Text>
          </Box>
        );
      },
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      render: (item: IJoinedCra) => (
        <ALink
          href={`/clients/establishments/${item?.estCustomer.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estCustomer)}
        </ALink>
      ),
    },
    {
      key: 'estProvider',
      text: 'ÉTABLIS. FOURN.',
      render: (item: IJoinedCra) => (
        <ALink
          href={`/providers/establishments/${item?.estProvider.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estProvider)}
        </ALink>
      ),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      render: (item: IJoinedCra) => (
        <ALink
          href={`/providers/contractors/${item?.contractor.uuid}`}
          target="_blank"
        >
          {getFullName(item.contractor)}
        </ALink>
      ),
    },
    {
      key: 'accountManager',
      text: 'SUIVI PAR',
      render: (item: IJoinedCra) => getFullName(item.accountManager),
    },
    {
      key: 'state',
      text: 'STATUT',
      render: (item: IJoinedCra) => {
        if (!item?.isOpen) {
          return (
            <Flex alignContent="center" justifyContent="center" mr={10}>
              <a data-for="main" data-tip="" data-iscapture="true">
                <InfoIcon fontSize={25} />
              </a>
              <ReactTooltip
                id="main"
                place={'top'}
                variant={'dark'}
                float={true}
                style={{ backgroundColor: Theme.colors.primary.default }}
              >
                <Text color="white">
                  La saisie sera disponible à partir du dernier jours ouvrable
                  du mois{' '}
                  {new Date(item?.month)?.getUTCMonth() === 3 ? "d'" : 'de '}
                  {MONTH_FR[new Date(item?.month)?.getUTCMonth()]}
                </Text>
              </ReactTooltip>
            </Flex>
          );
        } else {
          return (
            <Status variantColor={STATUS_COLOR[item.state]}>
              {item.state !== ECraStatus.TO_BE_FILLED
                ? FR_STATUS[item.state]
                : FR_STATUS[ECraStatus.TO_BE_SUBMITTED]}{' '}
              {(item.state === ECraStatus.REJECTED ||
                item.state === ECraStatus.VALIDATED) &&
                ` le ${formatDate(item.stateChangedAt || '')}`}
            </Status>
          );
        }
      },
      hidden: status !== 'awaiting' && status !== 'all',
    },
    {
      key: 'nbWorkingDays',
      text: 'NB. DE JOURS',
      hidden: status === 'awaiting',
      render: (item: IJoinedCra) => {
        return item?.nbWorkingDays === undefined
          ? 'N/A'
          : item.nbWorkingDays === 0
            ? '0'
            : item.nbWorkingDays;
      },
    },
    {
      key: 'invoice',
      text: 'BDL FOURNISSEUR',
      hidden: status === 'awaiting',
      render: (item: IJoinedCraWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;

        if (item?.invoices?.customer?.sage?.documentType) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        if (!customerReference) {
          return (
            <>
              <a data-for={item.refCra} data-tip="" data-iscapture="true">
                <ReactTooltip
                  id={item.refCra}
                  place={'top'}
                  variant={'dark'}
                  float={false}
                  style={{ backgroundColor: Theme.colors.error.default }}
                >
                  <Text color="white" fontSize={11}>
                    Le bon de commande client est requis pour créer les bons de
                    livraison dans gescom.
                  </Text>
                </ReactTooltip>
                <ALink
                  href={`/delivery/missions/${item?.mission?.reference}/client`}
                >
                  <Status variantColor="error">BDC Client manquant</Status>
                </ALink>
              </a>
            </>
          );
        }
        if (item?.nbWorkingDays === 0) {
          return <Status variantColor="success">Non créé dans GESCOM</Status>;
        }
        if (bdlProvider) {
          return <Status variantColor="success">Créé dans GESCOM</Status>;
        }
        if (!bdlProvider) {
          return <Status variantColor="error">BDL fournisseur manquant</Status>;
        }
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      hidden:
        status !== 'validated' && status !== 'archived' && status !== 'all',
      render: (item: IJoinedCra) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.refCra + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      hidden: status === 'archived',
      render: (item: IJoinedCraWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;

        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        if (item?.invoices?.customer?.sage?.documentType) {
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        /* CHECK PERMISSION FIX CRA */
        // const invoices = item?.invoices;
        // const isTeamLeader = checkUserHasRole(me, 'TEAM_LEADER');
        const userHavePermissionToFix = true;
        // if (
        //   bdlProvider ||
        //   bdlCustomer ||
        //   //@ts-ignore
        //   invoices?.provider?.status === EInvoiceStatus?.VALIDATED
        // ) {
        //   if (!isTeamLeader) {
        //     userHavePermissionToFix = false;
        //   }
        // }
        return (
          <Flex alignItems="center" justifyContent={'flex-start'}>
            <Box width={1 / 2}>
              <ALink href={`/orders/cra/${item.refCra}/informations`}>
                <Link iconLeft={<EyeIcon />}></Link>
              </ALink>
            </Box>
            <Box
              width={1 / 2}
              marginLeft={!checkDisplayCraMenuList(item, bdlProvider) ? 20 : ''}
            >
              {checkDisplayCraMenuList(item, bdlProvider) && (
                <>
                  <Menu
                    align="right"
                    menuItems={
                      <>
                        {canDisplayResendButton(item) && (
                          <MenuItem>
                            <ReSendButton cra={item} />
                          </MenuItem>
                        )}
                        {canDisplayVisibilityButton(item) && (
                          <MenuItem>
                            <ActivationButton cra={item} />
                          </MenuItem>
                        )}
                        {canDisplayValidateButton(item) && (
                          <MenuItem>
                            <Link
                              iconLeft={<CheckIcon />}
                              onClick={async () => {
                                await showValidateCraModal({
                                  layer: -1,
                                  fileLocation: item?.proof?.fileLocation,
                                  fileName: item?.proof?.fileName,
                                  confirmLabel: `Valider ( ${item?.nbWorkingDays} jours )`,
                                  previousLabel: '<',
                                  nextLabel: '>',
                                  hookStore: useListCraStore,
                                  refCra: item.refCra,
                                  onConfirm: async () => {
                                    await queryClient.refetchQueries({
                                      queryKey: ['Cras'],
                                      type: 'active',
                                    });
                                  },
                                  rejectLabel: 'Refuser',
                                  onRejectCra: async (
                                    cra: IJoinedCraWithInvoice
                                  ) => {
                                    await showRejectCraModal({
                                      cra: cra,
                                    });
                                  },
                                });
                              }}
                            >
                              Valider
                            </Link>
                          </MenuItem>
                        )}
                        {canDisplayProofButton(item) && (
                          <MenuItem>
                            <Link
                              iconLeft={<EyeIcon />}
                              onClick={() =>
                                showDisplayPdfModal({
                                  fileLocation: item.proof?.fileLocation || '',
                                  fileName:
                                    item.proof?.fileName || 'justificatif.pdf',
                                })
                              }
                            >
                              Voir le justificatif
                            </Link>
                          </MenuItem>
                        )}
                        {checkDisplayCreateGescomBDLButton(
                          item,
                          bdlProvider
                        ) && (
                          <MenuItem>
                            <Link
                              onClick={() =>
                                showCreateGescomBDLModal({
                                  mission: item?.mission,
                                  delivreyOrders: { bdlProvider, bdlCustomer },
                                  cra: item,
                                  customerReference:
                                    customerReference as string,
                                  refetchQueryKey: 'Cras',
                                })
                              }
                              iconLeft={<AddIcon />}
                            >
                              Créer le BDL Fourn. dans GESCOM
                            </Link>
                          </MenuItem>
                        )}
                        {canDisplayCorrectRejectButton(item) && (
                          <MenuItem>
                            <Link
                              iconLeft={<EditIcon />}
                              onClick={async () =>
                                userHavePermissionToFix
                                  ? showPatchCraValidatedModal({ cra: item })
                                  : await showDialogModal({
                                      title: "Vous n'avez pas la permission",
                                      text: (
                                        <Text variant="p">
                                          Vous devez avoir le role de{' '}
                                          <b>responsable d'équipe</b> pour
                                          pouvoir modifier ce CRA.
                                        </Text>
                                      ),
                                      confirmLabel: "J'ai bien compris",
                                    })
                              }
                            >
                              Corriger / Refuser le CRA
                            </Link>
                          </MenuItem>
                        )}
                        {/*
                    - Check CRA status
                    - Only CRAs with existing BL can generate the customer invoice
                    - Check  customer invoice  status
                    - TODO CHECK IF VALIDATED BY SAGE !
                  */}
                        {canDisplayGenerateSendCustomerInvoiceButton(item) && (
                          <MenuItem>
                            <Link
                              iconLeft={<SendIcon />}
                              onClick={() =>
                                showGenerateInvoiceModal({
                                  invoiceUuid: item?.invoices?.customer
                                    ?.uuid as string,
                                })
                              }
                            >
                              Générer et envoyer la facture client
                            </Link>
                          </MenuItem>
                        )}
                      </>
                    }
                  >
                    <DotsIcon
                      fill={Theme?.colors?.primary?.default}
                      fontSize={20}
                    />
                  </Menu>
                </>
              )}
            </Box>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

const getSortBy = (
  filterParams: string,
  sortString: string,
  initialSate: boolean
) => {
  let sortby = '';
  if (filterParams === 'to-be-validated') {
    sortby = `-hasFastCash fastCashDeadline ${
      sortString?.includes('-submittedAt') ? '-submittedAt' : 'submittedAt'
    }`;
  } else {
    sortby = sortString;
  }
  if (filterParams === 'all') {
    sortby = '-createdAt';
  }
  if (sortString?.includes('month') || sortString?.includes('submittedAt')) {
    sortby = `${sortby} refCra`;
  }
  return sortby;
};

export const CRAList = () => {
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const { filter: filterParams } = useParams<{
    filter:
      | 'all'
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'by-customer';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'CRA et factures',
        path: '/orders',
      },
      {
        label: 'CRA',
        path: '/orders/cra',
      },
      {
        label:
          filterParams === 'all'
            ? 'Tous'
            : filterParams === 'awaiting'
              ? 'en attente'
              : filterParams === 'to-be-validated'
                ? 'à valider'
                : filterParams === 'archived'
                  ? 'archivés'
                  : 'validés',
        path: '/orders/cra/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    filter,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    statusSelect,
    page,
    isOpen,
    isHidden,
    submittedAt,
    validatedAt,
    internalCommercial,
    // providerSelected,
    // clientSelected,
    multiClientSelected,
    multiProviderSelected,
    contractorSelected,
    limit,
    updateListCraStore,
    resetListCraStore,
    getNbActiveFilters,
  } = useListCraStore();
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const { me } = useMe();
  const additionalFiltersFields = [
    'multiProviderSelected',
    'multiClientSelected',
    'contractorSelected',
    'submittedAt',
    'validatedAt',
    'internalCommercial',
  ];
  //@ts-ignore
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const buildQuery = () => {
    const $and = [];
    if (startDate && endDate) {
      $and?.push({
        $expr: {
          $gte: [{ $toLong: '$month' }, getTime(startDate)],
        },
      });
      $and.push({
        $expr: {
          $lte: [{ $toLong: '$month' }, getTime(add(endDate, { months: 1 }))],
        },
      });
    }
    if (validatedAt) {
      $and?.push({
        $expr: {
          $gte: [{ $toLong: '$validatedAt' }, getTime(validatedAt)],
        },
      });
      $and.push({
        $expr: {
          $lte: [
            { $toLong: '$validatedAt' },
            getTime(add(validatedAt, { days: 1 })),
          ],
        },
      });
    }
    if (submittedAt) {
      $and?.push({
        $expr: {
          $gte: [{ $toLong: '$submittedAt' }, getTime(submittedAt)],
        },
      });
      $and.push({
        $expr: {
          $lte: [
            { $toLong: '$submittedAt' },
            getTime(add(submittedAt, { days: 1 })),
          ],
        },
      });
    }
    return {
      craValidatedBy:
        filterParams === 'by-customer' || filterParams === 'to-be-validated'
          ? filterParams === 'by-customer'
            ? EMissionCraValidatedBy?.CUSTOMER_CONTACT
            : EMissionCraValidatedBy?.ACCOUNT_MANAGER
          : undefined,
      accountManager: checkDefaultManagerSelected(me, managerSelected)
        ? managerSelected
        : undefined,
      'mission.internalCommercial':
        internalCommercial !== null ? internalCommercial : undefined,
      contractor: contractorSelected ? contractorSelected : undefined,
      estCustomer:
        multiClientSelected && multiClientSelected?.length > 0
          ? { $in: multiClientSelected?.map((mps: any) => mps?.value) }
          : undefined,
      estProvider:
        multiProviderSelected && multiProviderSelected?.length > 0
          ? { $in: multiProviderSelected?.map((mps: any) => mps?.value) }
          : undefined,
      isOpen: isOpen ? true : undefined,
      isHidden: isHidden ? true : undefined,
      state:
        filterParams === 'all' && statusSelect !== 'N/A'
          ? statusSelect
          : !statusSelect || statusSelect === 'N/A'
            ? filterParams === 'awaiting'
              ? {
                  $in: [
                    ECraStatus.TO_BE_FILLED,
                    ECraStatus.TO_BE_SUBMITTED,
                    ECraStatus.REJECTED,
                  ],
                }
              : filterParams === 'archived'
                ? ECraStatus.ARCHIVED
                : filterParams === 'to-be-validated' ||
                    filterParams === 'by-customer'
                  ? ECraStatus.TO_BE_VALIDATED
                  : ECraStatus.VALIDATED
            : statusSelect,
      $and: $and?.length > 0 ? $and : undefined,
      $or: debouncedFilterQuery
        ? [
            {
              refCra: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'mission.title': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    };
  };
  const {
    data: craQuery,
    status,
    isFetching,
  } = useCraFindMany({
    joinInvoice: true,
    filterObject: buildQuery(),
    limit: limit,
    skip: limit * page,
    sort: getSortBy(filterParams, sortString, initialSate),
  });

  const loading = status === 'pending';
  const totalCount = craQuery?.totalCount || 0;
  let items: Itemize<IJoinedCra>[] | [] | undefined = [];
  items = craQuery?.cras?.map(cra => ({
    key: cra.refCra + '_' + cra.createdAt || '',
    ...cra,
  }));
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.CRA,
        query: {
          filter: buildQuery(),
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };
  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateListCraStore({ order, sortedBy: shortBy });
    },
    []
  );

  const showMessage = useShowMessage();

  const ReSendButton = ({ cra }: { cra: IJoinedCra }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        craSendReminder({
          refCra: cra.refCra,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Votre demande de CRA a bien été renvoyée');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };
  const ActivationButton = ({ cra }: { cra: IJoinedCra }) => {
    const { mutateAsync: onActivation, status } = useMutation({
      mutationFn: () =>
        craActivation({
          refCras: [cra.refCra],
          action: cra?.isHidden
            ? ECraVisibilityActions.SHOW
            : ECraVisibilityActions.HIDE,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success') {
      showMessage('success', 'Votre demande a bien été prise en compte');
      queryClient.refetchQueries({
        queryKey: ['Cras'],
        type: 'active',
      });
    }
    return (
      <Link
        iconLeft={
          cra?.isHidden ? (
            <EyeIcon fill={Theme?.colors?.success?.default} />
          ) : (
            <SvgEyeCloseIcon fill={Theme?.colors?.warning?.default} />
          )
        }
        onClick={() => onActivation()}
      >
        {cra?.isHidden ? "Afficher sur l'espace fournisseur" : 'Cacher'}
      </Link>
    );
  };
  React.useEffect(() => {
    updateListCraStore({
      page: page,
      craToBeValidated: craQuery?.cras?.filter(
        c => c?.state === ECraStatus?.TO_BE_VALIDATED
      ),
    });
  }, [craQuery]);

  React.useMemo(() => {
    if (filterParams === 'awaiting' || filterParams === 'all') {
      updateListCraStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: true,
        initialSate,
        statusSelect: 'N/A',
      });
    } else {
      updateListCraStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: undefined,
        initialSate,
        statusSelect: 'N/A',
      });
    }
  }, [filterParams]);
  return (
    <>
      <Header tabs={Tabs}>
        <Text variant="h1">CRA</Text>
      </Header>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={filterParams === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                //@ts-ignore
                updateListCraStore({ searchQuery: e?.target?.value });
              }}
            />
          </Box>

          <Box width={filter === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={ERoles.ACCOUNT_MANAGER}
              onChange={value => updateListCraStore({ managerSelected: value })}
              value={managerSelected}
            />
          </Box>
          <Box width={filter === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateListCraStore({ startDate: value as Date, page: 0 });
              }}
              setEndDate={value => {
                updateListCraStore({ endDate: value as Date, page: 0 });
              }}
            />
          </Box>
          {filter === 'awaiting' && (
            <Box width={3 / 12} mr={10}>
              <FormLabel>Statuts</FormLabel>
              <StaticSelect
                options={[
                  {
                    label: 'À saisir',
                    value: ECraStatus.TO_BE_FILLED,
                    key: ECraStatus.TO_BE_FILLED,
                  },
                  {
                    label: 'À soumettre',
                    value: ECraStatus.TO_BE_SUBMITTED,
                    key: ECraStatus.TO_BE_SUBMITTED,
                  },
                  {
                    label: 'Refusé',
                    value: ECraStatus.REJECTED,
                    key: ECraStatus.REJECTED,
                  },
                  //"TO_BE_FILLED", "TO_BE_SUBMITTED", "REJECTED"
                ]}
                onChange={value =>
                  updateListCraStore({ statusSelect: value as string })
                }
                value={statusSelect}
              />
            </Box>
          )}
          {(filter === 'awaiting' || filter === 'all') && (
            <Box width={3 / 12} mr={10}>
              <FormLabel></FormLabel>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <Box>
                  <CheckBox
                    checked={isOpen}
                    onChange={() => updateListCraStore({ isOpen: !isOpen })}
                    id="isOpen"
                  >
                    {'CRA ouverts à la saisie uniquement'}
                  </CheckBox>
                </Box>
              </Flex>
            </Box>
          )}
          <Flex
            width={3 / 10}
            mt={'20px'}
            justifyContent={'space-around'}
            alignItems="center"
          >
            <SideBar
              title="Filtres des CRA"
              customLinkIcon={<AddIcon />}
              buttonType="link"
              buttonText="Plus de filtres"
            >
              <CraFiltersList />
            </SideBar>
            {initialSate === false &&
              getNbActiveFilters(additionalFiltersFields) > 0 && (
                <Box
                  backgroundColor={Theme?.colors?.primary?.default}
                  border={`1px solid ${Theme?.colors?.primary?.default}`}
                  borderRadius={'50%'}
                  height={15}
                  width={15}
                  ml={-9}
                  mt={-4}
                >
                  <Flex justifyContent={'center'}>
                    <Text color="white" fontSize={'10px'}>
                      {' '}
                      {getNbActiveFilters(additionalFiltersFields)}
                    </Text>
                  </Flex>
                </Box>
              )}
            <Box>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <Link
                  isDisabled={initialSate}
                  iconLeft={
                    <RefreshIcon
                      fill={
                        initialSate ? Theme?.colors?.grey?.default : undefined
                      }
                    />
                  }
                  onClick={() => (!initialSate ? resetListCraStore({}) : false)}
                >
                  Réinitialiser
                </Link>
              </Flex>
            </Box>
            <Button
              ml={'5px'}
              isLoading={isLoadingExport}
              onClick={() => onExportToExcel()}
            >{`Exporter`}</Button>
          </Flex>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedCra>>
            isLoading={isFetching}
            columns={columns(
              filterParams,
              ReSendButton,
              ActivationButton,
              showMessage,
              me
            )}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateListCraStore({ page })}
          />
        )}
      </Container>
    </>
  );
};
