import {
  craSendReminder,
  deleteCra,
  FR_STATUS,
  STATUS_COLOR,
  useCraFindMany,
  craFindMany,
  removeOffsetDate,
  useMe,
} from '@commons';
import {
  ECraStatus,
  ECraDeleteErrorType,
  IJoinedMission,
  EMissionStatus,
  ETaskStatus,
} from '@freelancelabs/teoreme-commons';
import {
  IJoinedCra,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons/dist/lib/api/responses/cra';
import { showPatchCraValidatedModal } from 'components/modals/cra/PatchCraValidated';
import { ALink } from 'components/ALink';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { showRejectCraModal } from 'components/modals/RejectCraModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Button,
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Table,
  TableColumn,
  Text,
  BlocInformation,
  CustomToolTip,
  CheckBox,
} from 'components/ui';
import {
  AddIcon,
  CheckIcon,
  DeleteIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  InfoIcon,
  RefreshIcon,
} from 'components/ui/icons';
import {
  getTime,
  eachMonthOfInterval,
  add,
  isBefore,
  setDate,
  differenceInMonths,
} from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import { kebabCase } from 'lodash';
import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@commons';
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { showGenerateCraInPastModal } from 'components/modals/missions/GenerateCraInPast';
import { showValidateCraModal } from 'components/modals/DisplayValidateCraModal';
import { Theme } from 'styles';
import {
  checkDisplayCreateGescomBDLButton,
  taskTypeMapping,
  getCraStatus,
  canDisplayResendButton,
  canDisplayDeleteCraButton,
  canDisplayValidateButton,
  canDisplayProofButton,
  canDisplayCorrectRejectButton,
  checkDisplayCraMissionMenuList,
} from 'helpers';
type Item = Itemize<IJoinedCra>;
const LIMIT = 5;
// const Tabs = (
//   <>
//     <Tab variant="secondary" href={'/orders/cra/awaiting'}>
//       En attente
//     </Tab>
//     <Tab variant="secondary" href={'/orders/cra/to-be-validated'}>
//       A valider
//     </Tab>
//     <Tab variant="secondary" href={'/orders/cra/validated'}>
//       Validés
//     </Tab>
//     <Tab variant="secondary" href={'/orders/cra/archived'}>
//       Archivés
//     </Tab>
//   </>
// );

const columns = (
  statusSelect: string,
  ReSendButton: any,
  showMessage: any,
  DeleteButton: any,
  me: any
) => {
  let status = kebabCase(statusSelect).toLocaleLowerCase();
  if (
    statusSelect === ECraStatus.TO_BE_FILLED ||
    statusSelect === ECraStatus.TO_BE_SUBMITTED ||
    statusSelect === ECraStatus.REJECTED
  ) {
    status = 'awaiting';
  }
  if (statusSelect === 'N/A') {
    status = 'awaiting';
  }
  return [
    {
      key: 'refCra',
      text: 'N°',
      render: (item: IJoinedCra) => (
        <>
          <CustomToolTip
            text={item?.mission?.description}
            id={item?.refCra + '_description'}
          >
            <Text>{item?.refCra}</Text>
          </CustomToolTip>
        </>
      ),
    },
    {
      key: 'month',
      text: 'MOIS',
      sortKey: 'month',
      render: (item: IJoinedCra) =>
        `${new Date(item?.month)?.getUTCMonth() + 1}/${new Date(
          item?.month
        )?.getUTCFullYear()}`,
    },

    {
      key: 'state',
      text: 'STATUT',
      render: (item: IJoinedCra) => {
        return (
          <Status
            variantColor={
              item.isOpen === false ? 'grey' : STATUS_COLOR[item.state]
            }
          >
            {getCraStatus(item)}
          </Status>
        );
      },
    },
    {
      key: 'nbWorkingDays',
      text: 'NB. DE JOURS',
      render: (item: IJoinedCra) => {
        return item?.nbWorkingDays === undefined
          ? 'N/A'
          : item.nbWorkingDays === 0
            ? '0'
            : item.nbWorkingDays;
      },
    },
    {
      key: 'invoice',
      text: 'BDL FOURNISSEUR',
      //hidden: status === 'awaiting',
      render: (item: IJoinedCraWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;

        if (item?.invoices?.customer?.sage?.documentType) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        if (!customerReference) {
          return (
            <>
              <a data-for={item.refCra} data-tip="" data-iscapture="true">
                <ReactTooltip
                  id={item.refCra}
                  place={'top'}
                  variant={'dark'}
                  float={false}
                  style={{ backgroundColor: Theme.colors.error.default }}
                >
                  <Text color="white" fontSize={11}>
                    Le bon de commande client est requis pour créer les bons de
                    livraison dans gescom.
                  </Text>
                </ReactTooltip>
                <ALink
                  href={`/delivery/missions/${item?.mission?.reference}/client`}
                >
                  <Status variantColor="error">BDC Client manquant</Status>
                </ALink>
              </a>
            </>
          );
        }
        if (bdlProvider) {
          return <Status variantColor="success">Créés dans GESCOM</Status>;
        }
        if (!bdlProvider) {
          return <Status variantColor="grey">A créer dans GESCOM</Status>;
        }
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      render: (item: IJoinedCra) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.refCra + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      hidden: status === 'archived',
      render: (item: IJoinedCraWithInvoice) => {
        let isAutoBilling = false;
        const missionAutoBilling =
          item?.mission?.customer?.billingOptions?.isAutoBilling;
        const establishmentAutoBilling =
          item?.estCustomer?.customer?.billingOptions?.isAutoBilling;
        if (missionAutoBilling && establishmentAutoBilling) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          isAutoBilling = true;
        }

        let bdlCustomer = false;
        let bdlProvider = false;
        let BDLAction: any = false;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        if (item?.invoices?.customer?.sage?.documentType) {
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        if (bdlProvider && bdlCustomer) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          BDLAction = false;
        }
        /* CHECK PERMISSION FIX CRA */
        // const invoices = item?.invoices;
        // const isTeamLeader = checkUserHasRole(me, 'TEAM_LEADER');
        const userHavePermissionToFix = true;
        // if (
        //   bdlProvider ||
        //   bdlCustomer ||
        //   //@ts-ignore
        //   invoices?.provider?.status === EInvoiceStatus?.VALIDATED
        // ) {
        //   if (!isTeamLeader) {
        //     userHavePermissionToFix = false;
        //   }
        // }

        return (
          <Flex alignItems="center">
            <Box width={1 / 2}>
              <ALink href={`/orders/cra/${item.refCra}/informations`}>
                <Link iconLeft={<EyeIcon />}></Link>
              </ALink>
            </Box>
            <Box
              width={1 / 2}
              marginLeft={
                !checkDisplayCraMissionMenuList(item, bdlProvider) ? 20 : ''
              }
            >
              {checkDisplayCraMissionMenuList(item, bdlProvider) && (
                <Menu
                  align="right"
                  menuItems={
                    <>
                      {canDisplayDeleteCraButton(item) && (
                        <MenuItem>
                          <DeleteButton cra={item} />
                        </MenuItem>
                      )}
                      {canDisplayResendButton(item) && (
                        <MenuItem>
                          <ReSendButton cra={item} />
                        </MenuItem>
                      )}
                      {canDisplayValidateButton(item) && (
                        <MenuItem>
                          <Link
                            iconLeft={<CheckIcon />}
                            onClick={async () => {
                              await showValidateCraModal({
                                layer: -1,
                                fileLocation: item?.proof?.fileLocation,
                                fileName: item?.proof?.fileName,
                                confirmLabel: `Valider ( ${item?.nbWorkingDays} jours )`,
                                refCra: item.refCra,
                                onConfirm: async () => {
                                  await queryClient.refetchQueries({
                                    queryKey: ['Cras'],
                                    type: 'active',
                                  });
                                },
                                rejectLabel: 'Refuser',
                                onRejectCra: async () => {
                                  await showRejectCraModal({
                                    cra: item,
                                  });
                                },
                              });
                            }}
                          >
                            Valider
                          </Link>
                        </MenuItem>
                      )}
                      {canDisplayProofButton(item) && (
                        <MenuItem>
                          <Link
                            iconLeft={<EyeIcon />}
                            onClick={() =>
                              showDisplayPdfModal({
                                fileLocation: item.proof?.fileLocation || '',
                                fileName:
                                  item.proof?.fileName || 'justificatif.pdf',
                              })
                            }
                          >
                            Voir le justificatif
                          </Link>
                        </MenuItem>
                      )}
                      {canDisplayCorrectRejectButton(item) && (
                        <MenuItem>
                          <Link
                            iconLeft={<EditIcon />}
                            onClick={async () =>
                              userHavePermissionToFix
                                ? showPatchCraValidatedModal({ cra: item })
                                : await showDialogModal({
                                    title: "Vous n'avez pas la permission",
                                    text: (
                                      <Text variant="p">
                                        Vous devez avoir le role de{' '}
                                        <b>responsable d'équipe</b> pour pouvoir
                                        modifier ce CRA.
                                      </Text>
                                    ),
                                    confirmLabel: "J'ai bien compris",
                                  })
                            }
                          >
                            Corriger / Refuser le CRA
                          </Link>
                        </MenuItem>
                      )}
                      {checkDisplayCreateGescomBDLButton(item, bdlProvider) && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              showCreateGescomBDLModal({
                                mission: item?.mission,
                                delivreyOrders: { bdlProvider, bdlCustomer },
                                cra: item,
                                customerReference: customerReference as string,
                                refetchQueryKey: 'Cras',
                              })
                            }
                            iconLeft={<AddIcon />}
                          >
                            Créer le BDL Fourn. dans GESCOM
                          </Link>
                        </MenuItem>
                      )}
                    </>
                  }
                >
                  <DotsIcon
                    fill={Theme?.colors?.primary?.default}
                    fontSize={20}
                  />
                </Menu>
              )}
            </Box>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];
};

type CRAMissionListProps = {
  mission: IJoinedMission;
};
export const CRAMissionList = ({ mission }: CRAMissionListProps) => {
  const { me } = useMe();
  const [missingCra, setMissingCra] = React.useState<Date[] | []>([]);
  const location = useLocation<{ location: any }>();
  //@ts-ignore
  const stateRefCra = location?.state?.refCra;
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('desc');
  const [sortedBy, setSortedBy] = React.useState('month');
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    stateRefCra || undefined
  );
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [statusSelect, setStatusSelect] = React.useState<string>('N/A');
  const [page, setPage] = React.useState(0);
  const [isHidden, setIsHidden] = React.useState<boolean | undefined>(false);

  const hiddenCondition = isHidden
    ? {
        isHidden: true,
      }
    : {
        $or: [{ isHidden: { $exists: false } }, { isHidden: false }],
      };

  const {
    data: craQuery,
    status,
    isFetching,
  } = useCraFindMany({
    joinInvoice: true,
    filterObject: {
      state: statusSelect !== 'N/A' ? statusSelect : undefined,
      mission: mission?.reference,
      ...hiddenCondition,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$month' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$month' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      $or: debouncedFilterQuery
        ? [
            {
              refCra: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    },
    limit: LIMIT,
    skip: LIMIT * page,
    sort: sortString + ' refCra',
  });

  const loading = status === 'pending';
  const totalCount = craQuery?.totalCount || 0;

  const items = craQuery?.cras?.map(cra => ({
    key: cra.refCra + '_' + cra.createdAt || '',
    ...cra,
  }));

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      setSortedBy(shortBy);
      setOrder(order);
    },
    []
  );

  const showMessage = useShowMessage();

  const ReSendButton = ({ cra }: { cra: IJoinedCra }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        craSendReminder({
          refCra: cra.refCra,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Votre demande de CRA a bien été renvoyée');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };
  const getDeleteErrorMessage = (cra: any, errorType: ECraDeleteErrorType) => {
    if (errorType === ECraDeleteErrorType.UNDELETABLE_INVOICE) {
      return `Le cra ${cra?.refCra} n’a pu être supprimé car une facture est associée à ce CRA.`;
    }
    if (errorType === ECraDeleteErrorType.NOT_FOUND_CRA_REF) {
      return `Echec de la suppression du CRA ${cra?.refCra} (CRA introuvable).`;
    }
    if (errorType === ECraDeleteErrorType.NOT_AUTHORIZE_STATUS) {
      return `Vous n'êtes pas autorisé à supprimer ce CRA.`;
    }
    return `Erreur sur la suppression du CRA.`;
  };
  const DeleteButton = ({ cra }: { cra: IJoinedCra }) => {
    return (
      <Link
        iconLeft={<DeleteIcon />}
        onClick={() =>
          showDialogModal({
            title: 'Supprimer un CRA',
            text: `Êtes-vous sûr de vouloir supprimer le CRA ${cra?.refCra} ?`,
            confirmLabel: 'Supprimer',
            cancelLabel: 'Ne pas Supprimer',
            //beforeValidation
          }).then(async action => {
            if (action) {
              const deleteResponse = await deleteCra({
                refCras: [cra?.refCra],
              });
              const firstError: any = deleteResponse?.errorDeleteArray.length
                ? deleteResponse.errorDeleteDetailsArray[0]
                : undefined;
              if (firstError) {
                const errorMessage = getDeleteErrorMessage(
                  cra,
                  firstError?.type
                );
                showMessage('error', `${errorMessage}`);
              } else {
                showMessage(
                  'success',
                  `Le cra ${cra?.refCra} à bien été supprimé`
                );
              }
              queryClient.refetchQueries({
                queryKey: ['Cras'],
                type: 'active',
              });
            }
          })
        }
      >
        Supprimer
      </Link>
    );
  };
  const avalaibleCreateCRA = async () => {
    const missingCra: Date[] | [] = [];
    try {
      if (
        mission &&
        (mission?.status === EMissionStatus.VALIDATED ||
          mission?.status === EMissionStatus.TERMINATED)
      ) {
        const firstCra = await craFindMany({
          filterObject: {
            mission: mission?.reference,
          },
          sort: 'month refCra',
          limit:
            differenceInMonths(
              mission?.endAt as Date,
              mission?.startAt as Date
            ) + 2,
          skip: 0,
        });
        const monthOfInterval = eachMonthOfInterval({
          start: mission?.startAt as Date,
          end: mission?.endAt as Date,
        });
        monthOfInterval?.forEach(month => {
          const isFind = firstCra?.cras?.find(cra => {
            return (
              cra?.month?.toISOString() ===
              removeOffsetDate(month).toISOString()
            );
          });
          if (!isFind) {
            const firstOfMonth = setDate(new Date(), 1);
            if (isBefore(removeOffsetDate(month), firstOfMonth)) {
              //@ts-ignore
              missingCra.push(removeOffsetDate(month));
            }
          }
        });
      }
    } catch (e) {
      //
    }
    //@ts-ignore
    setMissingCra(missingCra);
  };
  React.useEffect(() => {
    if (mission) {
      avalaibleCreateCRA();
    }
  }, [mission, craQuery]);
  React.useEffect(() => {
    setPage(0);
  }, [statusSelect]);

  return (
    <>
      {missingCra?.length > 0 && (
        <Flex justifyContent={'flex-end'} width={1 / 1}>
          <BlocInformation width={1 / 1}>
            <Text width={2 / 3} mr={'5px'}>
              Certains CRA pour cette mission n'ont pas été générés, si vous le
              souhaitez vous pouvez les générer mannuellement.
            </Text>
            <Button
              onClick={() =>
                showGenerateCraInPastModal({
                  mission: mission as IJoinedMission,
                  missingCra,
                }).then(() => avalaibleCreateCRA())
              }
            >
              Générer les CRA manquants
            </Button>
          </BlocInformation>
        </Flex>
      )}
      <Container p="20px 0">
        <Box ml={20} mb={10}>
          <CheckBox
            checked={isHidden ?? undefined}
            onChange={() => {
              setIsHidden(!isHidden);
            }}
            id="isHidden"
          >
            {'CRA cachés au fournisseur'}
          </CheckBox>
        </Box>
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                //@ts-ignore
                setSearchQuery(e.target.value);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                setStartDate(value as Date);
                setPage(0);
              }}
              setEndDate={value => {
                setEndDate(value as Date);
                setPage(0);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel>Statuts</FormLabel>
            <StaticSelect
              placeholder="Tous les statuts"
              options={[
                {
                  label: 'Tous les statuts',
                  value: 'N/A',
                  key: 'N/A',
                },
                // {
                //   label: 'A venir',
                //   value: ECraStatus.PENDING,
                //   key: ECraStatus.PENDING,
                // },
                {
                  label: 'À saisir',
                  value: ECraStatus.TO_BE_FILLED,
                  key: ECraStatus.TO_BE_FILLED,
                },
                {
                  label: 'À soumettre',
                  value: ECraStatus.TO_BE_SUBMITTED,
                  key: ECraStatus.TO_BE_SUBMITTED,
                },
                {
                  label: 'À valider',
                  value: ECraStatus.TO_BE_VALIDATED,
                  key: ECraStatus.TO_BE_VALIDATED,
                },
                {
                  label: 'Validé',
                  value: ECraStatus.VALIDATED,
                  key: ECraStatus.VALIDATED,
                },
                {
                  label: 'Refusé',
                  value: ECraStatus.REJECTED,
                  key: ECraStatus.REJECTED,
                },
                {
                  label: 'Archivé',
                  value: ECraStatus.ARCHIVED,
                  key: ECraStatus.ARCHIVED,
                },

                //"TO_BE_FILLED", "TO_BE_SUBMITTED", "REJECTED"
              ]}
              onChange={value => setStatusSelect(value as string)}
              value={statusSelect}
            />
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && items && items?.length > 0 ? (
          <Table<Itemize<IJoinedCra>>
            isLoading={isFetching}
            columns={columns(
              statusSelect,
              ReSendButton,
              showMessage,
              DeleteButton,
              me
            )}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < LIMIT ? totalCount : LIMIT}
            onSortedChange={onSortedChange}
            onChangePage={page => setPage(page)}
          />
        ) : (
          <Flex
            height={'50vh'}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box>
              <Text>Aucun comptes-rendus d’activité pour cette mission</Text>
            </Box>
          </Flex>
        )}
      </Container>
    </>
  );
};
