import {
  ECraStatus,
  IJoinedCraWithInvoice,
  EMissionCraValidatedBy,
  IJoinedCra,
  ICra,
  EInvoiceStatus,
} from '@freelancelabs/teoreme-commons';
import { FR_STATUS, formatDate } from '@commons';

export const checkDisplayCreateGescomBDLButton = (
  cra: IJoinedCraWithInvoice,
  bdlProvider: boolean
) => {
  if (
    cra?.state === ECraStatus.VALIDATED &&
    cra?.nbWorkingDays &&
    !bdlProvider
  ) {
    return true;
  }
  return false;
};

export const getCraStatus = (cra: IJoinedCraWithInvoice) => {
  const useValidatedBy = cra?.state === ECraStatus.TO_BE_VALIDATED;
  const validatedBy =
    cra?.craValidatedBy === EMissionCraValidatedBy.CUSTOMER_CONTACT
      ? 'par le client'
      : cra?.craValidatedBy === EMissionCraValidatedBy.ACCOUNT_MANAGER
        ? 'par le chargé de compte'
        : '';
  const useChangeStateDate =
    cra?.state === ECraStatus.REJECTED || cra?.state === ECraStatus.VALIDATED;
  if (!cra?.isOpen) {
    return 'Non soumis';
  }
  return `${FR_STATUS[cra.state]} ${useValidatedBy ? validatedBy : ''} ${
    useChangeStateDate ? ` le ${formatDate(cra?.stateChangedAt || '')}` : ''
  }`;
};

export const canDisplayResendButton = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  const allowedCraStatus = [
    ECraStatus.REJECTED,
    ECraStatus.TO_BE_FILLED,
    ECraStatus.TO_BE_SUBMITTED,
  ];

  return allowedCraStatus.includes(cra?.state);
};

export const canDisplayVisibilityButton = (cra: IJoinedCraWithInvoice) => {
  const allowedCraStatus = [ECraStatus.REJECTED];

  return allowedCraStatus.includes(cra?.state);
};

export const canDisplayDeleteCraButton = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  const allowedCraStatus = [
    ECraStatus.REJECTED,
    ECraStatus.TO_BE_FILLED,
    ECraStatus.TO_BE_SUBMITTED,
  ];

  return allowedCraStatus.includes(cra?.state);
};

export const canDisplayProofButton = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  const allowedCraStatus = [
    ECraStatus.VALIDATED,
    ECraStatus.REJECTED,
    ECraStatus.TO_BE_VALIDATED,
  ];

  return cra.proof && allowedCraStatus.includes(cra?.state);
};

export const canDisplayValidateButton = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  return cra.state === ECraStatus.TO_BE_VALIDATED;
};

export const canDisplayCorrectRejectButton = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  return cra.state === ECraStatus.VALIDATED;
};

export const canDisplayGenerateSendCustomerInvoiceButton = (
  cra: IJoinedCraWithInvoice
) => {
  // - Check CRA status
  // - Only CRAs with existing BL can generate the customer invoice
  // - Check  customer invoice  status
  // - TODO CHECK IF VALIDATED BY SAGE !
  const res =
    cra?.state === ECraStatus?.VALIDATED &&
    cra?.invoices?.customer?.status === EInvoiceStatus?.PROJECTED &&
    cra?.invoices?.provider?.sage?.invoiceId &&
    !cra?.invoices?.customer?.sage?.invoiceId
      ? true
      : false;
  return res;
};

export const checkDisplayCraMenuList = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice,
  bdlProvider: boolean
): boolean => {
  return (
    checkDisplayCreateGescomBDLButton(
      cra as IJoinedCraWithInvoice,
      bdlProvider
    ) ||
    canDisplayProofButton(cra) ||
    canDisplayResendButton(cra) ||
    canDisplayValidateButton(cra) ||
    canDisplayCorrectRejectButton(cra) ||
    canDisplayGenerateSendCustomerInvoiceButton(cra as IJoinedCra)
  );
};
export const checkDisplayCraMissionMenuList = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice,
  bdlProvider: boolean
): boolean => {
  return (
    checkDisplayCreateGescomBDLButton(
      cra as IJoinedCraWithInvoice,
      bdlProvider
    ) ||
    canDisplayProofButton(cra) ||
    canDisplayResendButton(cra) ||
    canDisplayDeleteCraButton(cra) ||
    canDisplayValidateButton(cra) ||
    canDisplayCorrectRejectButton(cra)
  );
};
