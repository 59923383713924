import { create } from 'zustand';
import { IJoinedCraWithInvoice } from '@freelancelabs/teoreme-commons';
import { countDiffStates } from '@commons';
enum filterType {
  ALL = 'all',
  AWAITING = 'awaiting',
  VALIDATED = 'validated',
  TOBEVALIDATED = 'to-be-validated',
  ARCHIVED = 'archived',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type MultiValueType = {
  label: string;
  value: string;
};
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  statusSelect?: string;
  page: number;
  isOpen?: boolean;
  craToBeValidated?: IJoinedCraWithInvoice[] | [];
  providerSelected?: string;
  clientSelected?: string;
  multiProviderSelected?: MultiValueType[];
  multiClientSelected?: MultiValueType[];
  contractorSelected?: string;
  submittedAt: Date | null;
  validatedAt: Date | null;
  internalCommercial?: string;
  isHidden?: boolean | null;
  limit: number;
  updateListCraStore: (state: Partial<State>) => void;
  resetListCraStore: (state: Partial<State>) => void;
  getNbActiveFilters: (data: string[]) => number;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.TOBEVALIDATED,
  order: sortType?.DESC,
  sortedBy: 'month',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  multiProviderSelected: undefined,
  multiClientSelected: undefined,
  statusSelect: 'N/A',
  page: 0,
  isOpen: undefined,
  craToBeValidated: [],
  submittedAt: null,
  validatedAt: null,
  internalCommercial: undefined,
  isHidden: false,
  limit: 20,
};

const store = (set: any, getState: any) => ({
  ...initialStates,
  updateListCraStore: (state: Partial<State>) =>
    set({
      //initialSate: checkIfStoreIsInititalState(state, getState, initialStates),
      initialSate: false,
      page: 0,
      ...state,
    }),
  resetListCraStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
  getNbActiveFilters: (additionalFiltersVars: string[]) => {
    const currentStates = getState();
    return countDiffStates(initialStates, currentStates, additionalFiltersVars);
  },
});

export const useListCraStore = create<State>(store);
